/*
 * Essential imports
 */

import 'picturefill';
import 'lazysizes';

/*
 * Essential fixes
 */

// Preload and No-JS
setTimeout(() => {
    document.documentElement.classList.remove('preload');
    document.documentElement.classList.remove('no-js');
}, 300);

let resizeId;

window.addEventListener('resize', () => {
    clearTimeout(resizeId);
    document.documentElement.classList.add('preload');
    resizeId = setTimeout(() => {
        document.documentElement.classList.remove('preload');
    }, 300);
});

// Fix NodeList.forEach in IE11
if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach;
}

/*
 * Function imports
 */

import { INIT_accordion } from './functions/accordion';
import { INIT_forms } from './functions/forms';
import { INIT_eventLocationsMaps } from "./functions/event-locations-maps";
import { INIT_goTo } from './functions/goto';
import { INIT_modals } from './functions/modals';
import { INIT_showMore } from "./functions/show-more";
import { INIT_sliders } from "./functions/sliders";
import { INIT_toggleItem } from "./functions/toggle-item";

INIT_accordion();
INIT_forms();
INIT_eventLocationsMaps();
INIT_goTo();
INIT_modals();
INIT_showMore();
INIT_sliders();
INIT_toggleItem();

/*
 * FlashMessage
 */

import { FlashMessage } from './grit-form-handler';
export const msg = new FlashMessage('#flash-message', 7200);

/*
 * Polyfills
 */

import { INIT_polyfillHas } from './functions/polyfill-has';
INIT_polyfillHas();

/*
 * StickyHeader
 */

import StickyHeader from './functions/sticky-header';
new StickyHeader();

/*
 * Archive filters
 */

window.addEventListener('change', (event) => {
    const target = event.target;

    if (target.id === 'form-archive-terms') {
        document.body.classList.add('is-loading');
        window.location.href = target.value;
    }

    if (target.id === 'form-archive-past') {
        let searchParams = new URLSearchParams(window.location.search)

        if (target.checked) {
            searchParams.set('past', true);
        } else {
            searchParams.set('past', false);
        }

        let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
        history.pushState(null, '', newRelativePathQuery);

        document.body.classList.add('is-loading');
        window.location.reload();
    }
});
