import { Splide } from '@splidejs/splide';
import { loadScript } from './support';

export const INIT_eventLocationsMaps = () => {
    const gMapsApiKey = document.querySelector("meta[name='google_maps_api_key']").getAttribute('content');
    const gMaps = [...document.getElementsByClassName('js-event-locations-maps')];

    if (gMaps.length) {
        loadScript(`https://maps.google.com/maps/api/js?key=${gMapsApiKey}&callback=Function.prototype`).then(() => {
            gMaps.forEach((map) => {
                const mapID = map.id;
                const markers = JSON.parse(map.dataset.markers);
                const assetsPath = map.dataset.assets;
                const splideID = map.dataset.splide;

                if (mapID && markers && assetsPath && splideID) {
                    INIT_map(mapID, markers, assetsPath, splideID);
                }
            });
        });
    }

    const INIT_map = (mapID, markers, assetsPath, splideID) => {

        // Slider
        const sliderElem = document.getElementById(splideID);
        const arrowPrev = sliderElem.dataset.prev;
        const arrowNext = sliderElem.dataset.next;

        let slider = new Splide(`#${splideID}`, {
            rewind: true,
            arrows: false,
            pagination: false,
        });

        // Markers
        let markersAll = [];

        // Icons
        const icons = {
            'default': {
                url: `${assetsPath}marker.svg`,
                scaledSize: new google.maps.Size(48, 48),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(24, 48),
            },
            'active': {
                url: `${assetsPath}marker-active.svg`,
                scaledSize: new google.maps.Size(48, 48),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(24, 48),
            },
        };

        // Bounds
        let bounds = new google.maps.LatLngBounds();

        // Create Map
        let map = new google.maps.Map(document.getElementById(mapID), {
            mapTypeControl: false,
            fullscreenControl: false,
            streetViewControl: false,
            options: {
                gestureHandling: 'greedy'
            },
            scrollwheel: false,
        });

        // Add Marker
        const addMarker = (mrkr, i) => {

            // Create Marker
            let marker = new google.maps.Marker({
                position: new google.maps.LatLng(mrkr.lat, mrkr.lng),
                map: map,
                title: mrkr.title,
                icon: i === 0 ? icons['active'] : icons['default'],
                label: {
                    text: `${i + 1}`,
                    color: i === 0 ? '#2F1432' : '#F2C359',
                    className: 'g-google-map--marker--label',
                },
            });

            markersAll.push(marker);

            bounds.extend(marker.position);

            google.maps.event.addListener(marker, 'click', () => {
                if (markersAll) {
                    markersAll.forEach(marker => {
                        let label = marker.getLabel();

                        label.color = '#F2C359';

                        marker.setLabel(label);
                        marker.setIcon(icons['default']);
                    });
                }

                let label = marker.getLabel();

                label.color = '#2F1432';

                marker.setLabel(label);
                marker.setIcon(icons['active']);

                slider.go(parseInt(label.text) - 1);
            });
        }

        markers.forEach((marker, i) => {
            addMarker(marker, i);
        });

        // Center Map
        centerMap(map, markers, bounds);

        slider.on('moved', (newIndex) => {
            markersAll.forEach(marker => {
                let label = marker.getLabel();

                if (newIndex === (parseInt(label.text) - 1)) {
                    label.color = '#2F1432';

                    marker.setLabel(label);
                    marker.setIcon(icons['active']);
                } else {
                    label.color = '#F2C359';

                    marker.setLabel(label);
                    marker.setIcon(icons['default']);
                }
            });
        });

        slider.on('active', (e) => {
            e.slide.parentElement.style.height = e.slide.querySelector('.event-locations--slide').clientHeight + 'px'
        })

        if (arrowPrev && arrowNext) {
            const prevs = [...document.querySelectorAll(`.${arrowPrev}`)];
            const nexts = [...document.querySelectorAll(`.${arrowNext}`)];

            if (prevs && nexts) {
                slider.on('overflow', (isOverflow) => {
                    if (isOverflow) {
                        prevs.forEach(prev => prev.disabled = false);
                        nexts.forEach(next => next.disabled = false);
                    } else {
                        prevs.forEach(prev => prev.disabled = true);
                        nexts.forEach(next => next.disabled = true);
                    }
                });

                window.addEventListener('click', (event) => {
                    const target = event.target;

                    if (target.classList.contains(arrowPrev)) {
                        slider.go("<");
                    }

                    if (target.classList.contains(arrowNext)) {
                        slider.go(">");
                    }

                    if (target.dataset.splideGoto) {
                        slider.go(parseInt(target.dataset.splideGoto));
                    }
                });
            }
        }

        slider.mount();
    }

    const centerMap = (map, markers, bounds) => {
        if (markers.length == 1) {
            map.setCenter(new google.maps.LatLng(markers[0].lat, markers[0].lng));
            map.setZoom(12);
        } else {
            map.fitBounds(bounds);
        }
    }
};
