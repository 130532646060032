export const INIT_toggleItem = () => {
    window.addEventListener('click', event => {
        const dataset = event.target.dataset.toggleItem;

        if (dataset) {
            const data = JSON.parse(dataset);

            if (data) {
                const wrapper = document.getElementById(data.wrapper);
                const target = document.getElementById(data.target);

                if (wrapper && target) {
                    const items = [...wrapper.querySelectorAll('[data-toggle-item-target]')];

                    if (items) {
                        items.forEach(item => {
                            if (item !== target) {
                                item.classList.remove('is-open');
                            }
                        });
                    }

                    target.classList.toggle('is-open');
                }
            }
        } else {
            if (!event.target.closest('[data-toggle-item-target]')) {
                const items = [...document.querySelectorAll('[data-toggle-item-target]')];

                if (items) {
                    items.forEach(item => {
                        item.classList.remove('is-open');
                    });
                }
            }
        }
    });
};
