import FormHandler, { GoogleV2 } from "../grit-form-handler";
import { msg } from '../main';

export const INIT_forms = () => {
    const gRecaptchaSiteKey = document.querySelector("meta[name='google_recaptcha_site_key']").getAttribute('content');
    const forms = [...document.querySelectorAll('[data-form-handler]')];

    if (forms) {
        forms.forEach(form => {
            const dataAlerts = JSON.parse(form.dataset.formAlerts);

            new FormHandler(`#${form.id}`, {
                recaptcha: new GoogleV2(gRecaptchaSiteKey),
                flashMessage: msg,
                localisation: {
                    success: dataAlerts.success ? dataAlerts.success : 'Thank you for your submission.',
                    error: dataAlerts.error ? dataAlerts.error : 'There was an error with your submission.',
                },
                scrollToError: {
                    enabled: true,
                    offset: 0,
                },
                validate: {
                    onChange: true,
                    onSubmit: true,
                },
                onSuccess: (form, res) => {
                    // console.log('Success', form, res);
                    msg.flash(dataAlerts.success, true);
                },
                onError: (form, res) => {
                    // console.log('Error', form, res);
                    msg.flash(dataAlerts.error, false);
                },
            });
        });
    }

    // In-field labels
    window.addEventListener('keyup', (event) => {
        const target = event.target;

        if (target.dataset.infield) {
            target.setAttribute('value', target.value);
        }
    });
};